import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form'
import styled from 'styled-components'

import { colors, device, variables } from 'src/Styled/variables'

const IS_ROLE_NECESSARY_BORDER = '1px solid #EC5765'
const IS_ROLE_UNNECESSARY_BORDER = '1px solid #EEEEEE'

export const RegisterForm = styled.form`
  display: grid;
  gap: 16px;
  color: ${colors.mainText};
  margin: 0 auto;
  width: 100%;

  @media ${device.mobileMinimumMax} {
    min-width: unset;
  }
`

export const InputContainer = styled.div`
  display: grid;
  position: relative;
  width: 100%;
`

export const Input = styled.input<{ $Error?: boolean }>`
  background: ${colors.white};
  height: 48px;
  border: 1px solid
    ${({ $Error }) => ($Error ? colors.messegesRed : colors.greyStroke)};
  box-sizing: border-box;
  border-radius: 8px;
  font-size: ${variables.fontSize14};
  line-height: 21px;
  outline: none;
  padding: 16px;
  width: 100%;
  color: ${colors.mainText};
  transition: border 0.3s;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  &::placeholder {
    color: ${colors.greyTextWhiteBg};
  }
  &:focus {
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    color: ${colors.mainText};
  }
  &:-webkit-text-fill-color {
    color: ${colors.mainText} !important;
  }
  &:selected {
    color: ${colors.mainText} !important;
  }
  &:-webkit-autofill,
  -webkit-autofill:active,
  -webkit-autofill:focus,
  -webkit-autofill:hover {
    -webkit-text-fill-color: ${colors.mainText} !important;
    color: ${colors.mainText} !important;
  }
`

export const PhoneNumberInput = styled(PhoneInputWithCountry)<{
  $isError: boolean
}>`
  :root {
    --PhoneInputCountryFlag-aspectRatio: 1.333;
    --PhoneInput-color--focus: transparent;
  }
  .PhoneInput--focus {
    color: transparent;
    outline: none;
    -webkit-appearance: none;
  }
  .PhoneInputCountry {
    margin-top: -2px;
  }
  .PhoneInputCountrySelectArrow {
    display: none;
  }
  .PhoneInputCountryIcon--border {
    background-color: transparent !important;
    box-shadow: none !important;
  }
  background: ${colors.white};
  height: 48px;
  z-index: 1;
  border: 1px solid
    ${({ $isError }) => ($isError ? colors.messegesRed : colors.greyStroke)};
  border-radius: 8px;

  font-size: ${variables.fontSize14};
  line-height: 21px;
  outline: none;
  padding: 16px;
  width: 100%;
  margin-bottom: 8px;
  color: ${colors.mainText};
  transition: border 0.3s;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  & input {
    background: ${colors.white};
    border: none;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    &:-webkit-autofill,
    -webkit-autofill:active,
    -webkit-autofill:focus,
    -webkit-autofill:hover {
      -webkit-text-fill-color: ${colors.mainText} !important;
      color: ${colors.mainText} !important;
    }
  }

  &:focus {
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    color: ${colors.mainText};
  }
  &:-webkit-text-fill-color {
    color: ${colors.mainText} !important;
  }
  &:selected {
    color: ${colors.mainText} !important;
  }
  &:-webkit-autofill,
  -webkit-autofill:active,
  -webkit-autofill:focus,
  -webkit-autofill:hover {
    -webkit-text-fill-color: ${colors.mainText} !important;
    color: ${colors.mainText} !important;
  }
`

interface Props {
  isRoleNecessary: boolean
}

export const SelectContainer = styled.div<Props>`
  .ant-select-selector {
    &:before {
      content: '';
      height: 100%;
      width: 1px;
      background: ${colors.greyStroke};
      position: absolute;
      right: 52px;
      z-index: 1000;
    }
  }

  .ant-select-single {
    height: auto;
  }
  .ant-select-selector {
    background: ${colors.white};
    height: 48px;
    border: ${(props: Props) =>
      props.isRoleNecessary
        ? IS_ROLE_NECESSARY_BORDER
        : IS_ROLE_UNNECESSARY_BORDER} !important;
    border-radius: 8px;
    font-size: ${variables.fontSize14};
    line-height: 21px;
    outline: none;
    padding: 16px;
    color: ${colors.mainText};
    transition: border 0.3s;
  }
  .ant-select-item-option-state {
    color: ${colors.mainText};
    background-color: transparent;
  }
  .ant-select {
    width: 100%;
  }
  .ant-select-focused {
    outline: none;
    border: none;
    --antd-wave-shadow-color: none !important;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: ${(props: Props) =>
      props.isRoleNecessary
        ? IS_ROLE_NECESSARY_BORDER
        : IS_ROLE_UNNECESSARY_BORDER} !important;
    border-radius: 8px;
    font-size: ${variables.fontSize14};
    line-height: 21px;
    outline: none;
  }
  .ant-select-item
    .ant-select-item-option
    .ant-select-item-option-active
    .ant-select-item-option-selected {
    background: ${colors.white};
    border-radius: none;
  }
  .ant-select-item-option-selected {
    background-color: none;
  }
  .ant-select-item-option-active {
    background-color: none;
  }
  .ant-select-item-option-hover {
    background-color: none;
  }
  .ant-select-dropdown {
    background: ${colors.white};
    filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.25));
  }
  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 48px;
  }
  .ant-select-arrow {
    width: 12px;
    top: 40%;
    right: 22px;
    transition: transform 0.3s ease-in-out;
  }

  .ant-select-open {
    .ant-select-arrow {
      transition: transform 0.3s ease-in-out;
      transform: rotate(180deg);
    }
  }
  .ant-select-item {
    font-size: ${variables.fontSize14};
    line-height: 21px;
    color: ${colors.mainText};
    padding: 13.5px 16px;
  }
  .ant-select-item-option-active {
    background-color: ${colors.white};
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 16px;
  }
  .ant-select-focused .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-select-selector:hover,
  .ant-select-open .ant-select-selector,
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    ).ant-select-selector {
    border: ${(props: Props) =>
      props.isRoleNecessary
        ? IS_ROLE_NECESSARY_BORDER
        : IS_ROLE_UNNECESSARY_BORDER} !important;
    box-shadow: none !important;
  }
  .ant-select-item .ant-select-item-option .ant-select-item-option-active {
    padding: 8px 16px !important;
  }
  .ant-select-item {
    color: ${colors.mainText} !important;
  }
  .ant-select-item-option-active,
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: ${colors.white} !important;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: ${colors.white} !important;
    color: ${colors.mainText} !important;
  }
  .ant-select-item
    .ant-select-item-option
    .ant-select-item-option-active
    .ant-select-item-option-selected {
    background-color: ${colors.white} !important;
    color: ${colors.mainText} !important;
  }
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: ${colors.white} !important;
    padding: 16px 16px;
  }
  .ant-select-item .container-dropdown {
    padding: 13.5px 16px;
    border-bottom: 1px solid #f3f4f7;
  }
  .ant-select-single.ant-select-open.ant-select-selection-item {
    color: ${colors.messegesRed};
  }
  .ant-select-dropdown-menu {
    background-color: ${colors.mainText} !important;
  }
  .ant-select-dropdown-menu-item {
    word-spacing: 30px;
  }
  .ant-select-item-option-active {
    background-color: ${colors.mainText} !important;
  }
  .ant-select-single.ant-select-open .ant-select-selection-item {
    color: ${colors.mainText} !important;
  }
  .ant-select-selection-placeholder {
    color: ${colors.greyTextWhiteBg} !important;
    text-align: left;
  }
`

export const ErrorText = styled.div`
  text-align: left;
  color: ${colors.messegesRed};
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
  margin-top: 0px;
`

export const ExistingEmailBlock = styled.p`
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
  margin-top: 0px;
  margin-bottom: 16px;
  text-align: left;
`

export const ExistingEmailText = styled.span`
  color: ${colors.messegesRed};
  text-align: left;
`

export const RegisterLogin = styled.span`
  color: ${colors.green};
  border-bottom: 1px dotted ${colors.green};
  line-height: 21px;
`

export const BtnContainer = styled.div`
  width: 149px;
  margin: 0 auto;
  margin-top: 2px;
  margin-bottom: 24px;
  @media ${device.mobileMin} {
    margin-bottom: 25.5px;
  }
`

export const PhoneInfo = styled.div<{ color?: string }>`
  text-align: left;
  color: ${({ color }) => color || colors.green};
  font-weight: ${variables.fontWeight400};
  font-size: ${variables.fontSize14};
  line-height: 21px;
  margin-bottom: 8px;
  @media ${device.mobileMin} {
    margin-bottom: 16px;
  }
`
