import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { getAwaitingTracksSelector } from 'src/Redux/feed-process/selectors'
import { SpotifyPreviewTrackPlayer } from 'src/Components/SpotifyPreviewTrackPlayer'

import { TrackListItem } from '../../TrackListItem'
import { ReviewMobileProps, SkipMobileProps } from '../props'
import { ListLimitMessage } from '../styles'
import { EmptyTrackList } from '../../EmptyTrackList'

interface Props {
  openReviewModal: (props: ReviewMobileProps) => void
  openSkipModal: (props: SkipMobileProps) => void
}
const getTrackId = (url: string): string => {
  if (!url) {
    return ''
  }
  const urlParts = url.split('/')
  return urlParts[urlParts.length - 1]
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  align-self: stretch;
`
export const AwaitingCampaignsList: FC<Props> = (props) => {
  const awaitingCampaigns = useSelector(getAwaitingTracksSelector)
  const { openReviewModal, openSkipModal } = props
  const { t } = useTranslation()
  if (awaitingCampaigns.length) {
    return (
      <SpotifyPreviewTrackPlayer
        render={({ isPlaying, loading, onClick, currentTrackUrl }) => (
          <Wrapper>
            {awaitingCampaigns.map((el) => {
              if (el.id) {
                return (
                  <TrackListItem
                    key={el.id}
                    trackName={el.trackName}
                    trackImage={el.trackImage}
                    artistName={el.artistName}
                    matchedGenres={el.matchedGenres}
                    matchedPlaylists={el.matchedPlaylists}
                    loading={
                      loading && currentTrackUrl === getTrackId(el.trackUrl)
                    }
                    isPlaying={
                      isPlaying && currentTrackUrl === getTrackId(el.trackUrl)
                    }
                    onClickPlayer={(e) => onClick(e, getTrackId(el.trackUrl))}
                    openReviewModal={() =>
                      openReviewModal({ campaign_id: el.id })
                    }
                    openSkipModal={() => openSkipModal({ campaign_id: el.id })}
                  />
                )
              }
              return null
            })}
            <ListLimitMessage>
              {t('reviewsPage.yourListOfTracks')}
            </ListLimitMessage>
          </Wrapper>
        )}
      />
    )
  }
  return <EmptyTrackList>{t('reviewsPage.emptyAwaitingList')}</EmptyTrackList>
}
