import { createDraftSafeSelector } from '@reduxjs/toolkit'

import { RootState } from 'src/Redux'
import { SliceStatus } from 'src/Redux/types'
import dayJs from 'src/Helpers/dayjs'
import { APP } from 'src/Configs/App'
import { CampaignTypeEnum } from 'src/Types'

import { UserState } from '.'

const getState = (state: RootState): UserState => state.authentication.user

export const getUser = createDraftSafeSelector(getState, ({ user }) => user)

export const getUserEmail = createDraftSafeSelector(
  getUser,
  ({ email }) => email,
)
export const getUserIntercomHash = createDraftSafeSelector(
  getUser,
  ({ intercomHash }) => intercomHash,
)

export const getIsNewUserForHideLanguageMatchingExp = createDraftSafeSelector(
  getState,
  ({ user, status }) =>
    dayJs(user.createdAt).isAfter(
      dayJs(APP.DATE_IS_NEW_USER_HIDE_LANGUAGE_MATCHING_EXP),
    ) && status === SliceStatus.Succeeded,
)
export const getIsNewUserForRemovePaypalExp = createDraftSafeSelector(
  getState,
  ({ user, status }) =>
    dayJs(user.createdAt).isAfter(
      dayJs(APP.DATE_IS_NEW_USER_REMOVE_PAYPAL_EXP),
    ) && status === SliceStatus.Succeeded,
)

export const getIsNewUserForMinBudgetDecreaseExp = createDraftSafeSelector(
  getState,
  ({ user, status }) =>
    dayJs(user.createdAt).isAfter(
      dayJs(APP.DATE_IS_NEW_USER_MIN_BUDGET_DECREASE_EXP),
    ) && status === SliceStatus.Succeeded,
)

export const getIsUserLoading = createDraftSafeSelector(
  getState,
  ({ status }) => status === SliceStatus.Loading,
)
export const getUserRoleToReflect = createDraftSafeSelector(
  getState,
  ({ user }) => user.artist?.artist_role,
)

export const getUserPhone = createDraftSafeSelector(
  getState,
  ({ phone_number }) => phone_number,
)
export const getIsEmailBounced = createDraftSafeSelector(
  getState,
  ({ isEmailBounced }) => isEmailBounced,
)

export const getIsEmailConfirmed = createDraftSafeSelector(
  getState,
  ({ user }) => user.is_email_confirmed,
)

export const getVerified = createDraftSafeSelector(
  getState,
  ({ verified }) => verified,
)

export const getCountryName = createDraftSafeSelector(
  getState,
  ({ user }) => user.country,
)

export const getCuratorSpotifyId = createDraftSafeSelector(
  getState,
  ({ user }) => user.spotifyId,
)

export const getUserSelector = createDraftSafeSelector(
  getState,
  ({ user }) => user,
)

export const getIsFirstCampaign = createDraftSafeSelector(
  getState,
  ({ user }) => user?.is_first_campaign,
)

export const getUserId = createDraftSafeSelector(getState, ({ user }) =>
  user?.id ? user.id : user?.curatorId,
)

export const getInfluencerId = createDraftSafeSelector(
  getState,
  ({ user }) => user?.influencerId,
)

export const getAreTermsAccepted = createDraftSafeSelector(
  getState,
  ({ user }) => user.are_terms_accepted,
)

export const getTermsPosition = createDraftSafeSelector(
  getState,
  ({ user }) => user.terms_position || 1,
)

export const getIsNewTerm = createDraftSafeSelector(
  getState,
  ({ user }) => user.is_new_term,
)

export const getArtistBalance = createDraftSafeSelector(
  getUserSelector,
  ({ balance }) => Number(balance),
)

export const getIsNewFeed = createDraftSafeSelector(
  getState,
  ({ user }) => user?.is_new_feed,
)

export const getIsTiktokAllowed = createDraftSafeSelector(
  getState,
  ({ user }) => user?.artist?.is_tiktok_allowed,
)

export const getIsDeactivatedCurator = createDraftSafeSelector(
  getState,
  ({ user }) => user.isDeactivated,
)

export const getIsBlockedCurator = createDraftSafeSelector(
  getState,
  (auth) => auth.isBlocked,
)

export const getReferralCode = createDraftSafeSelector(
  getState,
  ({ referralCode }) => referralCode,
)

export const getIsBlockedCreatorSelector = createDraftSafeSelector(
  getState,
  ({ user }) => user.isBlocked,
)

export const getUserCampaignTypeSelector = createDraftSafeSelector(
  getState,
  ({ user }) =>
    user.is_first_campaign ? CampaignTypeEnum.FIRST : CampaignTypeEnum.BASIC,
)
