import { useEffect } from 'react'

import { useNavigate, useParams } from 'react-router-dom'

import { APP } from 'src/Configs/App'
import { VideoStatus } from 'src/Constants/enums'
import { rtkApi } from 'src/Services/rtkApi'
import { CampaignVideo, TikTokCampaignPricingModelEnum } from 'src/Types'
import { TiktokReviewParams } from 'src/Containers/TiktokReview'
import { campaignsList, details } from 'src/Router/routes'

import { EventDismissReasonsEnum } from '../creatorSubmittedSoundsApi'
import {
  CampaignPreModerationVideo,
  mapVideoToPreModerationStatus,
} from '../campaignVideos-process/selectors'

interface GetVideoByEventBody {
  eventId?: string
  campaignId?: string
}

export interface SetCampaignVideoRateResult {
  campaignId: number
  paidAmount: number
  rateVideo: number
  trackId: string
}

interface SetCampaignVideoRateBody {
  id: number
  feedback: string
  stars: number
}

const campaignVideoApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    getCampaignVideo: builder.query<
      CampaignPreModerationVideo | null,
      GetVideoByEventBody
    >({
      query: ({ campaignId, eventId }) => ({
        url: `${APP.TIKTOK_SERVER}/artist/tiktok/campaigns/${campaignId}/event/${eventId}`,
      }),
      transformResponse: (response: CampaignVideo) =>
        mapVideoToPreModerationStatus(response),
      providesTags: ['CampaignVideo'],
    }),
    setCampaignVideoRate: builder.mutation<
      SetCampaignVideoRateResult,
      SetCampaignVideoRateBody
    >({
      query: ({ id, feedback, stars }) => ({
        url: `${APP.TIKTOK_SERVER}/artist/tiktok/rate-video/${id}`,
        method: 'PUT',
        body: { feedback, stars },
      }),
      invalidatesTags: ['CampaignVideo'],
    }),
  }),
})

export const { useGetCampaignVideoQuery, useSetCampaignVideoRateMutation } =
  campaignVideoApi

interface CampaignVideoState {
  video?: CampaignPreModerationVideo | null
  isLoading: boolean
  pricingModel?: TikTokCampaignPricingModelEnum
  isVideoDeclinedOrRejected: boolean
  isVideoDeleted: boolean
  campaignID?: string
}

export const useCampaignVideo = (): CampaignVideoState => {
  const { campaignID, videoID } = useParams<keyof TiktokReviewParams>()
  const navigate = useNavigate()
  const {
    data: video,
    isFetching,
    isError,
  } = useGetCampaignVideoQuery(
    {
      campaignId: campaignID,
      eventId: videoID,
    },
    { skip: !campaignID || !videoID },
  )

  const isVideoDeclinedOrRejected =
    video?.status === VideoStatus.REJECTED ||
    video?.status === VideoStatus.DECLINED

  const isVideoDeleted =
    video?.status === VideoStatus.DISMISSED &&
    video?.dismissReason === EventDismissReasonsEnum.VIDEO_DELETED

  const pricingModel = video?.pricingModelVersion

  useEffect(() => {
    if (isError) {
      navigate(`${campaignsList}${details}/${campaignID}`)
    }
  }, [isError, campaignID, navigate])

  return {
    video,
    campaignID,
    pricingModel,
    isVideoDeclinedOrRejected,
    isVideoDeleted,
    isLoading: isFetching,
  }
}
