import { useNavigate } from 'react-router-dom'

import { useAppSelector } from 'src/Hooks/redux'
import {
  CampaignPlatformTypeEnum,
  SpotifyCampaign,
  TiktokCampaign,
} from 'src/Types/index'
import { setPlatform } from 'src/Redux/campaign-process/spotifyCampaignSchedule-process'
import { activeTabStorage } from 'src/Constants/constants'
import {
  campaignsList,
  scheduleCampaign,
  scheduleCampaignTikTok,
} from 'src/Router/routes'
import { getUserId } from 'src/Redux/auth-process/userSlice/selectors'
import { launchCampaignNotificationEvent } from 'src/Helpers/TagManager/CampaignsEvents'

import { useAppDispatch } from './redux'

interface UseOpenCampaignScheduleI {
  activeTab: string
  campaign: SpotifyCampaign | TiktokCampaign
}

export const useOpenCampaignSchedule = ({
  activeTab,
  campaign,
}: UseOpenCampaignScheduleI): {
  handleScheduleCampaign: () => void
} => {
  const { platformType } = campaign
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const userId = useAppSelector(getUserId)

  const storeActiveTab = (): void => {
    window.sessionStorage.setItem(activeTabStorage, activeTab)
  }

  const handleScheduleCampaign = (): void => {
    storeActiveTab()

    platformType && dispatch(setPlatform(platformType))

    launchCampaignNotificationEvent({ userId })

    const schedule =
      platformType === CampaignPlatformTypeEnum.SPOTIFY
        ? scheduleCampaign
        : scheduleCampaignTikTok
    navigate(`${campaignsList}${schedule}/${campaign.id}`)

    window.scrollTo(0, 0)
  }

  return {
    handleScheduleCampaign,
  }
}
