import { FunctionComponent, useEffect, useRef, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Select } from 'antd'
import ReCAPTCHA from 'react-google-recaptcha'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from 'react-phone-number-input'
import { useDebouncedCallback } from 'use-debounce'

import {
  fillingRegisterFormEvent,
  locationEvent,
  phoneEnteredEvent,
} from 'src/Helpers/TagManager'
import 'react-phone-number-input/style.css'
import { arrPhoneCodes } from 'src/Helpers/validation'
import Button from 'src/Components/Buttons/Button'
import ArrowTotalBtnDown from 'src/Assets/Svg/arrowTotalDown.svg?react'
import { APP } from 'src/Configs/App'
import { colors } from 'src/Styled/variables'
import { DEBOUNCE_TIMER_500 } from 'src/Constants/constants'
import { getCountry } from 'src/Redux/auth-process/countrySlice/selectors'
import { getLoading } from 'src/Redux/auth-process/credentialsSlice/selectors'

import { FormDataProps, UserRegisterFormProps } from './UserRegisterFormProps'
import { registerSchema } from './schema'
import { ErrorMessage } from './ErrorMessage'
import { EmailExist } from './EmailExist'
import { UserRegisterFormLoginLink } from './UserRegisterFormLoginLink'

import {
  BtnContainer,
  Input,
  InputContainer,
  PhoneInfo,
  PhoneNumberInput,
  RegisterForm,
  SelectContainer,
} from './styles'

const { Option } = Select
const roles = ['artist', 'agent', 'label', 'manager', 'publisher']

const UserRegisterForm: FunctionComponent<UserRegisterFormProps> = ({
  onSubmit,
  emailExist,
  setGTMEvent,
  handleResetEmailExist,
}) => {
  const { t } = useTranslation()
  const isLoading = useSelector(getLoading)
  const country = useSelector(getCountry)

  const EMAIL_PLACEHOLDER = t('login.email')

  const captchaRef = useRef<ReCAPTCHA>(null)

  const {
    register,
    watch,
    handleSubmit,
    control,
    formState: { errors, isValid, isDirty },
  } = useForm<FormDataProps>({
    criteriaMode: 'firstError',
    reValidateMode: 'onBlur',
    mode: 'all',
    resolver: yupResolver(registerSchema),
  })

  const startFillingForm = (): void => {
    locationEvent()
    fillingRegisterFormEvent()
  }

  const [phoneNumberValidMessage, setPhoneNumberValidMessage] = useState('')
  const invalidNumberMessage = t('createCampaignPage.invalidNumber')
  const validNumberMessage = t('createCampaignPage.greatSMS')

  const watchPhone = watch('phone_number')

  useEffect(() => {
    if (!watchPhone) {
      setPhoneNumberValidMessage('')
    }
  }, [watchPhone])

  const showMessageAndSendGTMEvent = (): void => {
    ![invalidNumberMessage].includes(phoneNumberValidMessage) && setGTMEvent()
  }

  const isButtonDisable =
    !isValid || !isDirty || invalidNumberMessage === phoneNumberValidMessage

  const handleFormSubmit = handleSubmit(async (data) => {
    const token = await captchaRef.current?.executeAsync()
    onSubmit(data, token || 'broken token')
    captchaRef.current?.reset()
  })

  const changeMessageHandler = (phoneNumber: string): void => {
    const isPossiblePhone = isPossiblePhoneNumber(phoneNumber)
    const isValidPhone = isValidPhoneNumber(phoneNumber)

    if (!isPossiblePhone && !isValidPhone) {
      setPhoneNumberValidMessage(invalidNumberMessage)
      return
    }

    setPhoneNumberValidMessage(validNumberMessage)
    phoneEnteredEvent()
  }
  const debouncedOnChange = useDebouncedCallback((phoneNumber: string) => {
    changeMessageHandler(phoneNumber)
  }, DEBOUNCE_TIMER_500)

  return (
    <RegisterForm onSubmit={handleFormSubmit}>
      <InputContainer>
        <Input
          {...register('firstName')}
          placeholder={String(t('signup.firstName'))}
          $Error={Boolean(errors.firstName?.message)}
          type='text'
          onFocus={startFillingForm}
        />
        <ErrorMessage message={errors.firstName?.message} />
      </InputContainer>

      <InputContainer>
        <Input
          {...register('lastName')}
          placeholder={String(t('signup.lastName'))}
          $Error={Boolean(errors.lastName?.message)}
          type='text'
        />
        <ErrorMessage message={errors.lastName?.message} />
      </InputContainer>

      <InputContainer>
        <Input
          {...register('email')}
          placeholder={EMAIL_PLACEHOLDER}
          $Error={Boolean(errors.email?.message) && !emailExist}
          type='email'
          onFocus={() => {
            if (emailExist && handleResetEmailExist) {
              handleResetEmailExist()
            }
          }}
        />
        <ErrorMessage message={errors.email?.message} />
        <EmailExist emailExist={emailExist} />
      </InputContainer>

      <InputContainer>
        <SelectContainer isRoleNecessary={Boolean(errors.role?.message)}>
          <Controller
            control={control}
            name='role'
            render={({ field }) => (
              <Select
                {...field}
                placeholder={t('signup.role')}
                suffixIcon={<ArrowTotalBtnDown width={12} height={12} />}
                dropdownStyle={{
                  overflow: 'hidden',
                  backgroundColor: 'white',
                  borderRadius: '8px',
                  filter: 'drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.25))',
                  color: '#646877',
                  padding: '0',
                  position: 'fixed',
                }}
              >
                {roles.map((item) => (
                  <Option
                    key={item}
                    value={item}
                    style={{
                      color: '#646877',
                      backgroundColor: '#ffffff',
                      padding: '13.5px 16px',
                      fontWeight: 'normal',
                      borderBottom: '1px solid #F3F4F7',
                      borderRadius: '0px',
                      lineHeight: '21px',
                    }}
                  >
                    {t(`createCampaignPage.${item}`)}
                  </Option>
                ))}
              </Select>
            )}
          />
          <ErrorMessage message={errors.role?.message} />
        </SelectContainer>
      </InputContainer>

      <InputContainer>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
        {/* @ts-ignore*/}
        <PhoneNumberInput
          addInternationalOption={false}
          countryCallingCodeEditable={false}
          withCountryCallingCode
          international
          $isError={phoneNumberValidMessage === invalidNumberMessage}
          defaultCountry={country}
          control={control}
          type='tel'
          value={register('phone_number').name}
          name='phone_number'
          autoComplete='none'
          onChange={(number: string) => {
            if (number && !arrPhoneCodes.includes(number)) {
              debouncedOnChange(number)
            }
          }}
          onKeyDown={(event: KeyboardEvent) =>
            (event.key === 'e' || event.key === '-') && event.preventDefault()
          }
          onFocus={showMessageAndSendGTMEvent}
        />
        {phoneNumberValidMessage && (
          <PhoneInfo
            color={
              phoneNumberValidMessage === invalidNumberMessage
                ? colors.messegesRed
                : ''
            }
          >
            {phoneNumberValidMessage}
          </PhoneInfo>
        )}
      </InputContainer>
      <ReCAPTCHA
        ref={captchaRef}
        size='invisible'
        sitekey={APP.RE_CAPTCHA_SITE_KEY}
        badge={'bottomleft'}
      />
      <BtnContainer>
        <Button
          htmlType='submit'
          type='green'
          loading={isLoading}
          disabled={isButtonDisable || isLoading}
        >
          {t('login.continue')}
        </Button>
      </BtnContainer>
      <UserRegisterFormLoginLink emailExist={emailExist} />
    </RegisterForm>
  )
}

export default UserRegisterForm
