import { APP } from 'src/Configs/App'
import { rtkApi } from 'src/Services/rtkApi'

export interface Feature {
  name: string
  variation: number
}

interface GetUserFeaturesResponse {
  features: Feature[]
}

interface AddFeatureForUserBody {
  featureName: string
  variation: number
}

const ABFeaturesApi = rtkApi.injectEndpoints({
  endpoints(build) {
    return {
      getUserFeatures: build.query<GetUserFeaturesResponse, void | null>({
        query: () => ({
          url: `${APP.TIKTOK_SERVER}/feature-flags/user-features`,
        }),
        providesTags: ['Features'],
      }),
      addFeatureForUser: build.mutation<unknown, AddFeatureForUserBody>({
        query: (feature) => ({
          url: `${APP.TIKTOK_SERVER}/feature-flags/user-flag`,
          method: 'PUT',
          body: feature,
        }),
        invalidatesTags: ['Features'],
      }),
    }
  },
})

export const { useGetUserFeaturesQuery, useAddFeatureForUserMutation } =
  ABFeaturesApi
